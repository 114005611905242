import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import LocalizedLink from 'components/LocalizedLink';
import * as Styled from './styles';

export interface BlogFrontmatter {
    title: string;
    subtitle: string;
}

export interface BlogPostFrontmatter {
    slug: string;
    title: string;
    description: string;
    date: string;
    tags: string[];
    cover: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
}

interface Props {
    blog: { frontmatter: BlogFrontmatter };
    posts: Array<{ frontmatter: BlogPostFrontmatter }>;
}

const Posts: React.FC<Props> = ({ blog, posts }) => {
    const { frontmatter: blogFrontmatter } = blog;

    const sectionTitle = {
        title: blogFrontmatter.title,
        subtitle: blogFrontmatter.subtitle,
    };

    return (
        <Container>
            <TitleSection center title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
            <Styled.Posts>
                {posts.map((item) => {
                    const { title, slug, cover, description, date, tags } = item.frontmatter;

                    return (
                        <Styled.Post key={slug}>
                            <LocalizedLink to={`/blog/${slug}`}>
                                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
                                    <Styled.Card>
                                        <Styled.Image>
                                            <GatsbyImage
                                                image={cover.childImageSharp.gatsbyImageData}
                                                alt={title}
                                            />
                                        </Styled.Image>
                                        <Styled.Content>
                                            <Styled.Date>{date}</Styled.Date>
                                            <Styled.Title>{title}</Styled.Title>
                                            <Styled.Description>{description}</Styled.Description>
                                        </Styled.Content>
                                        <Styled.Tags>
                                            {/* eslint-disable-next-line no-shadow */}
                                            {tags.map((item) => (
                                                <Styled.Tag key={item}>{item}</Styled.Tag>
                                            ))}
                                        </Styled.Tags>
                                    </Styled.Card>
                                </motion.div>
                            </LocalizedLink>
                        </Styled.Post>
                    );
                })}
            </Styled.Posts>
        </Container>
    );
};

export default Posts;
