import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO, { SeoFrontmatter } from 'components/SEO';
import Posts, { BlogPostFrontmatter, BlogFrontmatter } from 'components/Posts';
import Newsletter, { NewsletterFrontmatter } from 'components/Newsletter';
import locales from 'constants/locales';

interface Props {
    pageContext: { locale: keyof typeof locales };
    data: {
        posts: { edges: [{ node: { id: string; frontmatter: BlogPostFrontmatter } }] };
        blog: { frontmatter: BlogFrontmatter };
        seo: {
            frontmatter: SeoFrontmatter;
        };
        newsletter: {
            frontmatter: NewsletterFrontmatter;
        };
    };
}

const BlogPage: React.FC<Props> = ({
    pageContext: { locale },
    data: { seo, posts, blog, newsletter },
}) => {
    return (
        <Layout locale={locale}>
            <SEO
                path="blog"
                title={seo.frontmatter.title}
                locale={seo.frontmatter.locale}
                description={seo.frontmatter.description}
            />
            <Posts
                blog={blog}
                posts={posts.edges.map((item) => ({ frontmatter: item.node.frontmatter }))}
            />
            <Newsletter data={newsletter} />
        </Layout>
    );
};

export const query = graphql`
    query BlogQuery($locale: String!, $localeISO: String!, $dateFormatString: String!) {
        seo: markdownRemark(
            frontmatter: { category: { eq: "seo-blog" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                description
                locale
            }
        }
        blog: markdownRemark(frontmatter: { category: { eq: "blog" }, locale: { eq: $locale } }) {
            frontmatter {
                title
                subtitle
            }
        }
        posts: allMarkdownRemark(
            filter: {
                frontmatter: {
                    category: { eq: "blog-post" }
                    published: { eq: true }
                    locale: { eq: $locale }
                }
            }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        slug
                        title
                        description
                        date(formatString: $dateFormatString, locale: $localeISO)
                        tags
                        cover {
                            childImageSharp {
                                gatsbyImageData(width: 800, layout: CONSTRAINED)
                            }
                        }
                    }
                }
            }
        }
        newsletter: markdownRemark(
            frontmatter: { category: { eq: "newsletter" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                description
                namePlaceholder
                emailPlaceholder
                submitPlaceholder
                mailchimpFormUrl
                successMessage
                alreadySubsribedMessage
                failMessage
            }
        }
    }
`;

export default BlogPage;
